import React,{lazy,Suspense} from 'react';
import '../index.css';

import { BrowserRouter, Route,Routes, Navigate  } from 'react-router-dom';
import isAuthenticated from '../Middleware/PrivateRoute';

const Dashboard = lazy(()=>import('../Pages/Dashboard'));
const Carlists = lazy(()=>import('../Pages/Cars/Carlists'));
const AddCars = lazy(()=>import('../Pages/Cars/Addcars'));
const Login = lazy(()=>import('../Pages/Login'));

const Hotellist = lazy(()=>import('../Pages/Hotels/Hotellist'));
const Addhotels = lazy(()=>import('../Pages/Hotels/Addhotels'));
const Operatorlist = lazy(()=>import('../Pages/Operators/Operatorlist'));
const Addoperators = lazy(()=>import('../Pages/Operators/Addoperators'));
const Cardetail = lazy(()=>import('../Pages/Cars/Cardetail'));
const Hoteldetail = lazy(()=>import('../Pages/Hotels/Hoteldetail'));
const Operatordetail = lazy(()=>import('../Pages/Operators/Operatordetail'));
const Profilelist = lazy(()=>import('../Pages/Profilelist'));
const Addrooms = lazy(()=>import('../Pages/Room/Addrooms'));
const Roomlist = lazy(()=>import('../Pages/Room/Roomlist'));
const Addlocation = lazy(()=>import('../Pages/Location/Addlocation'));
const Locationlist = lazy(()=>import('../Pages/Location/Locationlist'));
const Roomtype = lazy(()=>import('../Pages/Room/Roomtype'));
const Addroomtype = lazy(()=>import('../Pages/Room/Addroomtype'));
const Roomdetail = lazy(()=>import('../Pages/Room/Roomdetail'));
const PackageList = lazy(()=>import('../Pages/Package/PackageList'));
const AddPackage = lazy(()=>import('../Pages/Package/AddPackage'));

const PackageDetail = lazy(()=>import('../Pages/Package/PackageDetail'));
const Enquerieslist = lazy(()=>import('../Pages/Enquery/Enquerieslist'));
const GalleryList = lazy(()=>import('../Pages/Gallery/GalleryList'));
const AddGalleryImage = lazy(()=>import('../Pages/Gallery/AddGalleryImage'));


function Webrouting(){
    return(
            <>
                <BrowserRouter>
                <Suspense fallback={<>...Loading</>}>
                    <Routes>
                        <Route path="/" element={isAuthenticated() ? (<Dashboard />) : (
                            <Navigate to="/login" replace />)}/>
                        
                        <Route path="/dashboard" element={isAuthenticated() ? (<Dashboard />) : (
                            <Navigate to="/login" replace />)}/>

                        <Route path="/cars" element={isAuthenticated() ? (<Carlists />) : (
                            <Navigate to="/login" replace />)}/>

                        <Route path="/add-car" element={isAuthenticated() ? (<AddCars />) : (
                            <Navigate to="/login" replace />)}/>  
                             
                        <Route path="/edit-car/:car_id" element={isAuthenticated() ? (<AddCars />) : (
                            <Navigate to="/login" replace />)}/>       

                        <Route path="/car/:car_id" element={isAuthenticated() ? (<Cardetail />) : (
                            <Navigate to="/login" replace />)}/>  

                        <Route path="/location" element={isAuthenticated() ? (<Locationlist />) : (
                            <Navigate to="/login" replace />)}/>   

                        <Route path="/add-location" element={isAuthenticated() ? (<Addlocation />) : (
                            <Navigate to="/login" replace />)}/> 

                        <Route path="/edit-location/:location_id" element={isAuthenticated() ? (<Addlocation />) : (
                            <Navigate to="/login" replace />)}/>  

                          <Route path="/hotels" element={isAuthenticated() ? (<Hotellist />) : (
                            <Navigate to="/login" replace />)}/>  


                        <Route path="/add-hotels" element={isAuthenticated() ? (<Addhotels />) : (
                            <Navigate to="/login" replace />)}/>  

                        <Route path="/edit-hotel/:hotel_id" element={isAuthenticated() ? (<Addhotels />) : (
                            <Navigate to="/login" replace />)}/>       
                        
                        <Route path="/hotels/:hotel_id" element={isAuthenticated() ? (<Hoteldetail/>) : (
                            <Navigate to="/login" replace />)}/>

                        <Route path="/packages" element={isAuthenticated() ? (<PackageList />) : (
                            <Navigate to="/login" replace />)}/>  

                        <Route path="/add-package" element={isAuthenticated() ? (<AddPackage />) : (
                            <Navigate to="/login" replace />)}/>
                       
                            <Route path="/gallery" element={isAuthenticated() ? (<GalleryList />) : (
                                <Navigate to="/login" replace />)}/>  

                            <Route path="/add-gallery-image" element={isAuthenticated() ? (<AddGalleryImage />) : (
                            <Navigate to="/login" replace />)}/>      
                        
                       

                           

                        <Route path="/edit-package/:package_id" element={isAuthenticated() ? (<AddPackage />) : (
                            <Navigate to="/login" replace />)}/>       
                        
                        <Route path="/packages/:package_id" element={isAuthenticated() ? (<PackageDetail/>) : (
                            <Navigate to="/login" replace />)}/>    

                        <Route path="/rooms" element={isAuthenticated() ? (<Roomlist />) : (
                            <Navigate to="/login" replace />)}/>

                        <Route path="/rooms/:room_id" element={isAuthenticated() ? (<Roomdetail/>) : (
                            <Navigate to="/login" replace />)}/>

                        <Route path="/edit-room/:room_id" element={isAuthenticated() ? (<Addrooms />) : (
                            <Navigate to="/login" replace />)}/>

                        <Route path="/add-rooms" element={isAuthenticated() ? (<Addrooms />) : (
                            <Navigate to="/login" replace />)}/>  

                        <Route path="/room-type" element={isAuthenticated() ? (<Roomtype />) : (
                            <Navigate to="/login" replace />)}/>  

                        <Route path="/add-roomtype" element={isAuthenticated() ? (<Addroomtype />) : (
                            <Navigate to="/login" replace />)}/> 

                        <Route path="/edit-roomtype/:room_id" element={isAuthenticated() ? (<Addroomtype />) : (
                            <Navigate to="/login" replace />)}/> 


                        <Route path="/operators" element={isAuthenticated() ? (<Operatorlist />) : (
                            <Navigate to="/login" replace />)}/> 

                        <Route path="/add-operators" element={isAuthenticated() ? (<Addoperators />) : (
                            <Navigate to="/login" replace />)}/>    

                        <Route path="/edit-operator/:operator_id" element={isAuthenticated() ? (<Addoperators />) : (
                            <Navigate to="/login" replace />)}/>    

                        <Route path="/operators/:operator_id" element={isAuthenticated() ? (<Operatordetail />) : (
                            <Navigate to="/login" replace />)}/>   

                        <Route path="/enqueries" element={isAuthenticated() ? (<Enquerieslist />) : (
                            <Navigate to="/login" replace />)}/>       

                        <Route path="/login" element={isAuthenticated() ? (<Navigate to="/dashboard" replace />) : (
                           <Login /> )}/>

                        <Route path="/profilelist" exact element= {<Profilelist />}/>

        
                    </Routes>
                    </Suspense>
                </BrowserRouter>
            </>
    );
}
export default Webrouting;
